import { json, redirect, type ActionFunctionArgs } from '@remix-run/node'
import { createUserSupabase } from '~/lib/supabase'

import { loginForm } from '~/validation'

import Navbar from '~/components/navbar'
import SubmitButton from '~/components/SubmitButton'
import { Anchor } from '~/components/ui/link'
import { Content } from '~/components/ui/page-content'
import ValidatedForm from '~/components/ui/validated-form'
import TextInput from '~/components/ui/text-input'
import PasswordInput from '~/components/ui/password-input'

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()
	// const data = await validator.validate(formData)
	// if (data.error) return validationError(data.error)
	//
	const email = formData.get('email') as string
	const password = formData.get('password') as string
	const { supabase, headers } = createUserSupabase(request)

	const { error } = await supabase.auth.signInWithPassword({
		email,
		password
	})

	if (error) {
		console.error(`Login error`, error.message)
		return json({ errorMessage: error.message, success: false })
	}

	return redirect('/', {
		headers
	})
}

export default function Login() {
	return (
		<>
			<Navbar />
			<Content className='p-4'>
				<div className='mt-8 inline-flex w-full max-w-md flex-col gap-8'>
					<div>
						<h1 className='mb-2 text-h3 font-light'>Log in</h1>
						{/*
							<p className='text-small text-text-secondary'>
								Don&apos;t have an account?{' '}
								<Anchor $size='primary' href='/signup'>
									Sign up
								</Anchor>
							</p>
						*/}
					</div>
					<ValidatedForm
						formId='login-form'
						validator={loginForm}
					>
						{({ register, getError, resultErrorCode }) => (
							<>
								<TextInput
									autoFocus
									label='Email address'
									name='email'
									defaultValue=''
									placeholder='name@mail.com'
									errorMessage={getError('email')}
									type='email'
									register={register}
								/>
								<PasswordInput
									helper
									label='Password'
									name='password'
									defaultValue=''
									placeholder='Enter password'
									errorMessage={getError('password')}
									register={register}
								/>
								<div className='mt-4'>
									<SubmitButton
										label='Log in'
										submittingLabel='Logging in...'
									/>
								</div>
							</>
						)}
					</ValidatedForm>
				</div>
			</Content>
		</>
	)
}
